#AppBody {
  padding: 3em 5em;
  max-width: 700px;
}

h1, h2 {
  color: var(--heading-color);
}

#Configuration {
  width: 80%;
}

#LanguageToggle {
  color: var(--primary-color);
  background-color: var(--bg-color);
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  padding: 0.5em;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  font-family: OpenDyslexic3;
}

#LanguageToggle:hover {
  background-color: var(--primary-color);
  color: var(--bg-color);
  border: 2px solid var(--primary-color);
  border-radius: 5px;
}

#CharacterSelect {
  color: var(--primary-color);
  background-color: var(--bg-color);
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  margin-top: 1em;
  font-family: OpenDyslexic3;
  padding: 0.5em;
  width: 100%;
}

#CharacterSelect > option {
  border: 2px solid var(--bg-color);
  border-radius: 5px;
  margin: 0.1em;
}

#CharacterSelect > option:hover, #CharacterSelect > option:focus, #CharacterSelect > option:checked {
  background-color: var(--primary-color);
  color: var(--bg-color);
  border: 2px solid var(--primary-color);
  border-radius: 5px;
}

#TextBody {
  margin-top: 2em;
}

#TextBody p.highlighted {
  background-color: var(--bg-highlight);
}

b.same-character {
  display: none;
}

.speech > span, .direction > span, .marker > span {
  width: 100%;
  display: block;
  cursor: pointer;
}

.altLang {
  display: none !important;
}

/* account for fixed navbar when scrolling to anchor tags */
.marker {
  margin-top: -1em;
  padding-top: 1em;
}

@media (max-width: 900px) {
  #AppBody {
    padding: 1em 1em;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1em;
  }

  h1, h2, h3 {
    margin: 0.5em 0em;
  }
}