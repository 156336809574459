#dark-mode-button {
  height: 36px;
  width: 36px;
  margin: 0em 0.5em;
  /* TODO: find better icons with transparent backgrounds */
  background-image: url('./../assets/light-bulb-off.png');
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
}

#dark-mode-button.active {
  background-image: url('./../assets/light-bulb-on.png');
}