#TextNavigation {
  background-color: var(--bg-color);
  border-top: 2px solid #333;
  font-size: 12px;
  padding: 0.5em 1em;
  width: calc(100% - 2em);
  position: fixed;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

#TextNavigation > a {
  margin: 0em 0.5em;
  color: var(--font-color);
  font-weight: bold;
  text-decoration: none;
}

#TextNavigation > a:hover {
  text-decoration: underline;
}

#TextNavigation > a:visited {
  color: var(--font-color);
}

.marker-anchor-2 {
  display: none;
  font-weight: normal !important;
}

.sub-reveal {
  display: inline;
}

/* TODO: make it possible for the user to show/hide this navigation bar */