@font-face {
  font-family: "OpenDyslexic3";
  src: local("OpenDyslexic3"),
    url("./fonts/OpenDyslexic3-Regular.ttf") format("truetype");
  font-weight: normal;
}

:root {
  --primary-color: #302AE6;
  --secondary-color: #536390;
  --font-color: #424242;
  --bg-color: #fff;
  --heading-color: #292922;
  --bg-highlight: #aee;
}

[data-theme="dark"] {
  --primary-color: #9A97F3;
  --secondary-color: #818cab;
  --font-color: #e1e1ff;
  --bg-color: #161625;
  --heading-color: #818cab;
  --bg-highlight: #311;
}

body {
  margin: 0;
  font-family: OpenDyslexic3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font-color);
  background-color: var(--bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}
