header {
  background-color: var(--bg-color);
  border-bottom: 2px solid #333;
  font-size: 12px;
  padding: 0.5em 1em;
  display: flex;
  justify-content: space-between;
}

div#dark-mode-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}